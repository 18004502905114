import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import './player.css';
import { Row, Col } from 'react-bootstrap';

import UserHeader from '../UserHeader';
import { formatDate } from '../utils/utils';
import { highlightColumnColor, checkHealthScoreStatus, showHealthyToReturnDate, calculateHeight } from '../utils/dashboardCalculations';
import { Line } from 'rc-progress';
import DashboardTip from './../Tooltip/dashboardTip';
import currentStatus from '../../global/img/1.png'
import expectedReturn from '../../global/img/2.png'
import fullRecovery from '../../global/img/3.png'
import gameMissed from '../../global/img/4.png'


function Player(props) {
	const { dispatch, player, user, togglePlayerView } = props;
	let { playerID } = props;
	useEffect(() => {
		window.scrollTo(0, 0);
		const url = window.location.pathname.split('/');
		// eslint-disable-next-line react-hooks/exhaustive-deps
		playerID = playerID || url[url.length - 1];
		dispatch({
			type: 'GET_PLAYER',
			payload: { playerID }
		});
	}, []);

	const title = 'Health Scores for Player';
	// const redirectURL = `${user.sportsID}/dashboard`;
	if (player.isLoading)
		return (
			<div id='fullHeight'>
				<div id='loading' />
			</div>
		);
	const { sportsName, sportsID } = user;

	if (!player || !player.data) return null;

	const playerInfo = player && player.data && player.data.playerInfo[0];
	const playerHistory = player.data && player.data.playerHistory;
	const healthStatus = playerInfo && playerInfo.health_score ? checkHealthScoreStatus(playerInfo.health_score, 'player') : '';
	const teamHealthStatus = playerInfo && playerInfo.team_health_score ? checkHealthScoreStatus(playerInfo.team_health_score, 'team') : '';
	const healthScoreDesc = `Health score represents the overall impact of a players' past injuries to give an overall performance ranking`;
	const teamHealthScoreDesc = `Team health score represents the overall impact of all starting players' health scores to give an overall performance ranking`;
	const showReturnDate = showHealthyToReturnDate(playerInfo?.recovery_return_date, 3);
	return !playerInfo ? (
		<div className='playerMainDiv'>
			<UserHeader matchup={true} player={true} backgroundColor={'#f7f7f7'} title={title} />
			<h1 style={{ textAlign: 'center' }}>No info found for player</h1>
		</div>
	) : (
		<section className='playerMainSection'>
			<div className='playerMainDiv'>
				<UserHeader matchup={true} player={true} backgroundColor={'#f7f7f7'} returnFunction={() => togglePlayerView()} />
				{/* <div className="playerInfoMobileTeamLogoDiv">
                <img
                  src={playerInfo.team_logo}
                  alt="Team Logo"
                  className="playerInfoMobileTeamLogo"
                />
              </div> */}
			    <div class="d-flex justify-content-center align-items-center flex-column playerInfoRow">
					<div class="d-flex align-items-center profile_hdr">
						<figure class="player_logo text-center mb-0 me-3">
							<figcaption class="by_week">Bye Week: 12</figcaption>
							<img src={playerInfo.team_logo} alt={`${playerInfo.player_name}`} wdth="" className='playerInfoImg' />
						</figure>
						<div class="d-flex align-items-center player_name">
							<h2 class="h3 mb-0">{playerInfo.jersey} {playerInfo.nickname || playerInfo.player_name} ({playerInfo.position})</h2>
							{(playerInfo.injury_status === "Out" || playerInfo.injury_status === "Probable" || playerInfo.injury_status === "Questionable") && <span class="badge bg-danger ms-4">Injured</span>}
						</div>
					</div>
				

					<div className='playerInfoDiv'>
								
						<div class="d-flex flex-wrap justify-content-center align-items-center playerInfoStatsDiv">
							{sportsID !== 3 ? (
								<>
									<span className='playerInfoStatsTitle'><strong>HEIGHT</strong>: {calculateHeight(playerInfo.height)}</span>
									<span className='playerInfoStatsTitle'><strong>WEIGHT</strong>: {playerInfo.weight}</span>
								</>
							) : null}
							<span className='playerInfoStats'>
								<span className='playerInfoStatsTitle'><strong>AGE</strong>: {playerInfo.age}</span>
							</span>
							{sportsID !== 3 ? (
								
								<span className='playerInfoStatsTitle'><strong>YRS IN LEAGUE</strong>: {playerInfo.experience || 'N/A'}</span>
								
							) : null}
							{ playerInfo.status && <span className='playerInfoStatsTitle'><strong>Current Injury</strong>: {playerInfo.body_part}</span>}

						</div>

						{/* <div className='playerInfoStatsDiv playerInfoStatsMobileDiv'>
							{user?.sportsID !== 3 ? (
								<> 
									<span className='playerInfoStatsTitle'>HEIGHT: {calculateHeight(playerInfo.height)}</span>
									<span className='playerInfoStatsTitle'>WEIGHT: {playerInfo.weight}</span>
								</>
							) : null}
							
							<span className='playerInfoStatsTitle'>AGE: {playerInfo.age}</span>
							
							{sportsID !== 3 ? (
									<span className='playerInfoStatsTitle'>YRS IN LEAGUE: {playerInfo.experience || 'N/A'}</span>
								
							) : null}
							{
								playerInfo.status && <span className='playerInfoStatsTitle'>Current Injury: {playerInfo.body_part}</span>
							} 
						</div> */}
					</div>

					{/* Listing */}
					{
						(playerInfo.status === "Out" || playerInfo.status === "Probable" || playerInfo.status === "Questionable") &&
						<ul class="list-unstyled current_status w-100 text-start mt-3">
							<li>
								<em class="me-2"><img src={currentStatus} width="32" alt='' /></em> Current Status: {playerInfo?.injury_notes || 'N/A'}
							</li>
							<li>
								<em class="me-2"><img src={expectedReturn} width="32" alt='' /></em>
								Current Expected Return: {playerInfo?.recovery_return_date ? (new Date(playerInfo?.recovery_return_date)).toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' }) : 'N/A'}
							</li>
							<li>
								<em class="me-2"><img src={fullRecovery} width="32" alt=''  /></em>
								Estimated Full Recovery: {showHealthyToReturnDate(player?.recovery_return_date, 3) && player.recovery_return_date ? formatDate(true, player.recovery_return_date.split('T')[0], 'matchup') : 'No Recent Injury'}
							</li>
							<li>
								<em class="me-2"><img src={gameMissed} width="32" alt=''  /></em>
								Game Missed This Sesason: {playerInfo?.gameMissed || 'N/A'}
							</li>
						</ul>
					}

					{/* Progressbar */}
					<div class="w-100 text-center mt-3 playerInfoProgressDiv">
						<h6 className='playerInfoProgressLabel'>
							Player Health Score:
							<span className={`playerInfoProgressValue ${healthStatus.color}`}>{healthStatus.status}</span>
						</h6>
						<div class="d-flex flex-wrap flex-md-nowrap justify-content-center playerInfoProgressBarDiv">
							<div class="w-100 progressbar_elem displayTableCell">	
								<Line percent={playerInfo.health_score ? (playerInfo.health_score / 10) * 100 : 100} strokeWidth='4' trailWidth='4' trailColor='rgba(21, 21, 21, .06)' strokeColor={highlightColumnColor(playerInfo.health_score, 'cardSpread', 'playerhealth', true)} />
							</div>
							<div class="d-flex align-items-center mx-3 progress_value">
								<span className='playerInfoProgressBarValue displayTableCell'>{playerInfo.health_score ? `${playerInfo.health_score}/10` : `10/10`}</span>
								<span class="d-inline-flex justify-content-center align-items-center info_icon">
									<DashboardTip title='PLAYER HEALTH SCORE' description={healthScoreDesc} component={'player_health_score'} />
								</span>
							</div>
						</div>
					</div>
					
					<div class="w-100 text-center mt-3 playerInfoProgressDiv">
						<h6 className='playerInfoProgressLabel'>
							Team Health Score:
							<span className={`playerInfoProgressValue ${teamHealthStatus.color}`}>{teamHealthStatus.status}</span>
						</h6>
						<div class="d-flex flex-wrap flex-md-nowrap justify-content-center playerInfoProgressBarDiv">
							<div class="w-100 progressbar_elem displayTableCell">
								<Line percent={(playerInfo.team_health_score / 10) * 100} strokeWidth='4' trailWidth='4' trailColor='rgba(21, 21, 21, .06)' strokeColor={highlightColumnColor(playerInfo.team_health_score, 'cardSpread', 'teamhealth', true)} />
							</div>
							<div class="d-flex align-items-center mx-3 progress_value">
								<span className='playerInfoProgressBarValue displayTableCell'>{playerInfo.team_health_score}/10</span>
								<span class="d-inline-flex justify-content-center align-items-center info_icon">
									<DashboardTip title='TEAM HEALTH SCORE' description={teamHealthScoreDesc} component={'team_health_score'} />
								</span>
							</div>
						</div>
					</div>
			
				</div>		
			 
				<Row className='playerTotalInjuryRow container-fluid'>
					<Col lg={3} className='playerTotalInjuryCol'>
						<div className='playerTotalInjuryDiv'>
							<p className='playerTotalInjury'>TOTAL INJURIES LAST 12 MONTHS:</p>
							<p className='playerTotalInjuryValue'>
								{/* {playerInfo.total_1_year_injuries_count} */}
								{playerHistory?.length}
							</p>
						</div>
					</Col>
					<Col lg={3} className='playerTotalInjuryCol'>
						<div className='playerTotalInjuryDiv'>
							<p className='playerTotalInjury'>TOTAL INJURIES LAST 3 YEARS:</p>
							<p className='playerTotalInjuryValue'>{playerInfo.total_3_year_injuries_count || 0}</p>
						</div>
					</Col>
				</Row>
				<div className='playerHistoryDiv'>
					<p className='playerTotalInjuryHistory'>TOTAL INJURIES LAST 12 MONTHS:</p>
					{playerHistory &&
						playerHistory.map((history, i) => (
							<Row key={i} className='playerHistoryRow'>
								<Col lg={2} className='playerHistoryCol'>
									<p className='playerHistoryLabel'>INJURY</p>
									<p className='playerHistoryValue'>{history.body_part}</p>
								</Col>
								{/* <Col lg={5} className="playerHistoryCol">
                        <p className="playerHistoryLabel">DESCRIPTION</p>
                        <p className="playerHistoryValue">
                          {history.admin_injury_grade || history.final_injury_grade}
                        </p>
                      </Col> */}
								<Col lg={2} className='playerHistoryCol'>
									<p className='playerHistoryLabel'>INJURY GRADE</p>
									<p className='playerHistoryValue'>{history.admin_injury_grade || history.final_injury_grade}</p>
								</Col>
								<Col lg={3} className='playerHistoryCol'>
									<p className='playerHistoryLabel'>DATE</p>
									<p className='playerHistoryValue'>{history.injury_date ? formatDate(true, history.injury_date) : 'No Recent Injury'}</p>
								</Col>
							</Row>
						))}
				</div>
			</div>
		</section>
	);
}

const mapStateToProps = (state) => ({
	isLoading: state.isLoading,
	isLoaded: state.isLoaded,
	match: state.match,
	player: state.player,
	playerHistory: state.playerHistory,
	user: state.user
});

export default connect(mapStateToProps)(Player);
